import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLocation, setCity } from '../../../../redux/actions/filtersActions';


const Location = (props) => {
  const dispatch = useDispatch();
  const allCountries = useSelector((state) => state.filters.countries);
  const allOffers = useSelector((state) => state.offers.offers);
  const [countries, setCountries] = useState([]);

  const handleChange = (value) => {
    const country = countries.find((item)=>item.country === value);
    dispatch(setLocation(country ? country : null));
    dispatch(setCity(null));
  }
  function isOffer(country) {
    const offer = allOffers.find((item) => (item.country === country.country) || (country.country === "Remote" && item.remote));
    if(offer) {
      return true;
    }
  }
  useEffect(()=>{
    const withOffers = allCountries.filter((country) => isOffer(country));
    setCountries(withOffers);
  },[allCountries])
  
  return (
    <div>
      <span className="text-left text-base font-medium">Country: </span>
      <div className="inline-block relative w-48">
        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" onChange={(e)=>handleChange(e.target.value)}>
          <option value={null}>All</option>
          {countries.map(country => (
            <option>{country.country}</option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
  )
}

export default Location;