import React from 'react';

const JobDetails = (props) => {
  const { offer } = props;
  return (
    <div className="p-4">
      <div className="mb-4 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold">
        <p>Job Details</p>
      </div>
      <div className="flex justify-start">
        <span className="inline-block bg-d-blue rounded-full px-3 py-2 text-sm font-semibold text-white mr-2">{offer.language}</span>
        {offer.quickApply && (
          <span onClick={() => props.apply()} className="quick-apply inline-block bg-d-pink rounded-full px-3 py-2 text-sm font-semibold text-white mr-2">Quick Apply</span>
        )}
        {offer.remote && (
          <span className="inline-block bg-d-green rounded-full px-3 py-2 text-sm font-semibold text-white mr-2">Remote</span>
        )}
      </div>
    </div>
  );
}

export default JobDetails;
