export const possibleMainSkills = [
  { skill: 'JS', iconUrl: '/images/skill-icons/js-icon.svg' },
  { skill: 'Java', iconUrl: '/images/skill-icons/java-icon.svg' },
  { skill: 'Python', iconUrl: '/images/skill-icons/python-icon.svg' },
  { skill: 'Golang', iconUrl: '/images/skill-icons/go-icon.svg' },
  { skill: 'Android', iconUrl: '/images/skill-icons/android-icon.svg' },
  { skill: 'Swift', iconUrl: '/images/skill-icons/swift-icon.svg' },
  { skill: 'C/C++', iconUrl: '/images/skill-icons/c-icon.svg' },
  { skill: 'C#', iconUrl: '/images/skill-icons/csharp-icon.svg' },
  { skill: 'PHP', iconUrl: '/images/skill-icons/php-icon.svg' },
  { skill: 'Ruby', iconUrl: '/images/skill-icons/ruby-icon.svg' },
  { skill: 'SAP', iconUrl: '/images/skill-icons/sap-icon.svg' },
  { skill: 'Scala', iconUrl: '/images/skill-icons/scala-icon.svg' },
  { skill: 'AI', iconUrl: '/images/skill-icons/ai-icon.svg' },
  { skill: 'IoT', iconUrl: '/images/skill-icons/iot-icon.svg' },
  { skill: 'Blockchain', iconUrl: '/images/skill-icons/blockchain-icon.svg' },
  { skill: 'Security', iconUrl: '/images/skill-icons/security-icon.svg' },
  { skill: 'Devops', iconUrl: '/images/skill-icons/devops-icon.svg' },
  { skill: 'UI/UX', iconUrl: '/images/skill-icons/uiux-icon.svg' },
  { skill: 'IT', iconUrl: '/images/skill-icons/it-icon.svg' },
];

export const possibleSeniority = ["Senior", "Mid", "Junior"];
export const possibleLanguages = ["English", "German", "Danish", "Dutch", "Finnish", "French", "Norwegian", "Swedish"];
export const possibleCurrencies = ["EUR", "USD", "CHF", "NOK"];
