import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const PostJob = (props) => {
  return (
    <>
      <Helmet>
        <title>Post job offer - Duomly jobs</title>
        <meta charSet="utf-8" />
        <meta name="description" content='Precisely selected IT jobs from developers for developers' />
        <meta property="og:type" content='website'/>
        <meta property='og:image' content={process.env.S3_URL + 'jobs/social/jobs-open-graph.jpg'}></meta>
      </Helmet>
      <div id='post-job'>
        <div className='flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-10 md:max-w-2xl '>
          <div className='flex items-start px-4 py-6'>
            <div className=''>
              <div className='flex items-center justify-between'>
                <h2 className='text-lg font-semibold text-gray-900 -mt-1'>
                  Why post with us?
                </h2>
              </div>
              <div className='py-3 text-sm'>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    We reach ~<span className="text-lg font-bold text-d-green">100k IT people</span> per month
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    We have more than <span className="text-lg font-bold text-d-green">4000 IT people</span> only in our newsletter
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    We have almost <span className="text-lg font-bold text-d-green">10k IT subscribers</span> on our youtube channel
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    We have ~<span className="text-lg font-bold text-d-green">20k views</span> per month on our blog
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    We have about <span className="text-lg font-bold text-d-green">40k students</span> on our programming courses
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    All of these people can be a potential candidate for you
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    All of these numbers are quickly growing every day
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    We have very user-friendly UI with filtering
                  </div>
                </div>
                <div className='flex justify-start text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2'>
                  <span className='bg-green-400 h-2 w-2 m-2 rounded-full'></span>
                  <div className='flex-grow font-medium px-2'>
                    Easy apply feature, and candidates resume directly on your
                    email
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-10 md:max-w-2xl '>
          <div className='flex items-start px-4 py-6'>
            <div className=''>
              <div className='flex items-center justify-between'>
                <h2 className='text-lg font-semibold text-gray-900 -mt-1'>
                  How much it costs?
                </h2>
              </div>
              <div className='py-3 text-sm'>
                <div className='flex justify-start text-gray-700  rounded-md py-2 my-2'>
                  <div className='flex-grow font-medium'>
                  Now you can post your offers for <span className="text-lg font-bold text-d-green">FREE</span>, and your offer will be active <span className="text-lg font-bold text-d-green">until you will find the right candidate</span>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-10 md:max-w-2xl '>
          <div className='flex items-start px-4 py-6 w-full'>
            <div className='w-full'>
              <div className='flex items-center justify-between'>
                <h2 className='text-lg font-semibold text-gray-900 -mt-1'>
                  How to post the job offer?
                </h2>
              </div>
              <div className='py-3 text-sm'>
                <div className='flex justify-start text-gray-700 rounded-md py-2 my-2'>
                  <div className='flex-grow font-medium'>
                  Fill the form, and we'll inform you when the job offer will be visible
                  on the board.
                  </div>
                </div>
              </div>
              <Link className='bg-d-pink hover:d-pink-hover text-white font-bold py-2 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mt-2' to="/post-job-step-two">Post a Job</Link>
              {/* <PostJobForm /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostJob;
