import React from 'react';

const Description = (props) => {
  const { offer } = props;
  return (
    <div className="description long-text">
      {offer.aboutUs && (
        <div className='p-4'>
          <div className='mb-2 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold'>
            <p>About us</p>
          </div>
          <p className='text-gray-700 text-base'>{offer.aboutUs}</p>
        </div>
      )}
      {offer.description && (
        <div className='p-4'>
          <div className='mb-2 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold'>
            <p>Job Description</p>
          </div>
          <p className='text-gray-700 text-base'>{offer.description}</p>
        </div>
      )}
      {offer.responsibilities && (
        <div className='p-4'>
          <div className='mb-2 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold'>
            <p>Responsibilities</p>
          </div>
          <p className='text-gray-700 text-base'>{offer.responsibilities}</p>
        </div>
      )}
      {offer.requirements && (
        <div className='p-4'>
          <div className='mb-2 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold'>
            <p>Requirements</p>
          </div>
          <p className='text-gray-700 text-base'>{offer.requirements}</p>
        </div>
      )}
    </div>
  );
};

export default Description;
