import React, { useState } from 'react';

import Nav from '../../header/nav/Nav';
import Skills from '../../header/skills/Skills';
import MapContainer from '../../map/mapContainer/MapContainer';
import Filters from '../../header/filters/Filters';
import List from '../../list/List';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../shared/modal/Modal';
import { setModal } from '../../../redux/actions/filtersActions';

const Home = (props) => {
  const dispatch = useDispatch();
  const [mapVisible, showMap] = useState(false);
  const modal = useSelector((state) => state.filters.modal);
  return (
    <>
      <div className="top-filters">
        <Skills />
      <div className="filters-mobile flex">
        <button onClick={()=>dispatch(setModal(true))} className="filter-btn bg-d-pink hover:d-pink-hover text-white font-bold py-1 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mr-2">
            All filters
        </button>
      </div>
      </div>
      <div className="grid grid-cols-2">
        <div className={`p-6 pt-0 offers-list`}>
          <Filters />
          <div className="list">
            <List />
          </div>
        </div>
        <div className="map-col">
          <MapContainer />
        </div>
      </div>
      {modal && <Modal/>}
    </>
  );
}

export default Home;
