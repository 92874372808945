import React from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { convertSalary } from '../../../utils/helpers';

const BlankItem = (props) => {
  return (
    <div className='offer-item max-w rounded overflow-hidden shadow-lg p-4 bg-white mb-2'>
      <div className='flex items-center'>
        <div className='mt-1'>
          <div className='company-logo h-12 w-12 lg:w-12 flex-none bg-center bg-cover text-center overflow-hidden'>
            <img src='/images/worried.svg' alt='' />
          </div>
        </div>
        <div className='mt-4 md:mt-0 ml-4 flex-grow'>
          <div className='mb-2 flex justify-between uppercase tracking-wide text-sm text-d-blue font-bold'>
            <p>Oh no! Did you try to change filter?</p>
            <p className='text-d-green'>
              {convertSalary(100000)}-{convertSalary(250000)} EUR
            </p>
          </div>
          <div className='company-info flex justify-between'>
            <div>
              <p className='block mt-1 text-sm leading-tight font-medium text-gray-90'>
                <svg
                  className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z' />
                </svg>
                Duomly jobs
                <span className='mt-2 text-gray-600 text-xs'>
                  <svg
                    className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' />
                  </svg>
                  Everywhere
                </span>
              </p>
            </div>
            <div className='job-skills'>
              <span
                className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                key={shortid.generate()}
              >
                Coding
              </span>
              <span
                className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                key={shortid.generate()}
              >
                Positivity
              </span>
              <span
                className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                key={shortid.generate()}
              >
                Good mood
              </span>
            </div>
          </div>
          <div className='company-info-mobile'>
            <div>
              <p className='block mt-1 text-sm leading-tight font-medium text-gray-90'>
                <svg
                  className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z' />
                </svg>
                Duomly jobs
                <span className='mt-2 text-gray-600 text-xs'>
                  <svg
                    className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' />
                  </svg>
                  Everywhere
                </span>
              </p>
            </div>
            <div className='first-row'>
              <div className='job-skills'>
                <span
                  className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                  key={shortid.generate()}
                >
                  Coding
                </span>
                <span
                  className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                  key={shortid.generate()}
                >
                  Positivity
                </span>
                <span
                  className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                  key={shortid.generate()}
                >
                  Good mood
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankItem;
