import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import Home from '../views/home/Home';
import DetailsView from '../views/detailsView/DetailsView';
import { useDispatch, useSelector } from 'react-redux';
import { getOffers } from '../../redux/actions/offersActions';
import { getCountries, setLocationFilter } from '../../redux/actions/filtersActions';
import PostJob from '../views/postJob/PostJob';
import Nav from '../header/nav/Nav';
import Terms from '../views/terms/Terms';
import LocationModal from '../shared/modal/LocationModal';
import PostJobStepTwo from '../views/postJobStepTwo/PostJobStepTwo';

const NotFound = () => {
  return <Redirect to='/' />;
};

function Main() {
  const dispatch = useDispatch();
  const locationFilter = useSelector((state) => state.filters.locationFilter);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    fetch('https://ipwhois.app/json/')
      .then((resLoc) => resLoc.json())
      .then((location) => {
        if (
          location.continent &&
          (location.continent !== 'Europe' || location.country === 'Ukraine' || location.country === 'Russia') &&
          location.continent !== 'Oceania' &&
          location.continent !== 'North America'
        ) {
          dispatch(setLocationFilter());
        }
      }).catch().then(()=> {
        setChecked(true);
      }
      );
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
  }, []);

  const fetchCountries = () => {
    fetch('/data/locations.json')
      .then((res) => res.json())
      .then((data) => {
        dispatch(getCountries(data));
      });
  }

  useEffect(()=>{
    if(checked && !locationFilter) {
      fetch(process.env.BACKEND_URL + '/jobs/all')
      .then((res) => res.json())
      .then((data) => {
        if (data.offers) {
          dispatch(getOffers(data.offers));
          
        }
      }).catch(()=>{}).then(()=>fetchCountries());
    }
  }, [locationFilter, checked]);

  return (
    <>
      {locationFilter && <LocationModal/>}
      {checked && !locationFilter && (
        <Router>
          <div className='grid grid-cols-1'>
            <Nav />
          </div>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/offer/:id' component={DetailsView} />
            <Route exact path='/post-job' component={PostJob} />
            <Route exact path='/post-job-step-two' component={PostJobStepTwo} />
            <Route exact path='/terms' component={Terms} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      )}
    </>
    
  );
}

export default Main;
