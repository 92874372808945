import React, { Fragment, useEffect, createRef, useState } from 'react';
// import swift from '../../assets/icons/swift.svg';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import { possibleMainSkills } from '../../../utils/possibleOptions';
import { withRouter } from 'react-router-dom';
import { convertSalary } from '../../../utils/helpers';

const icon = (type, isCurrent) => {
  let skill = possibleMainSkills.find((skill) => skill.skill === type);
  if(!skill) {
    skill = {
      url: '/images/skill-icons/default-icon.svg'
    }
  }

  const elem = new L.Icon({
    iconUrl: skill.iconUrl,
    iconRetinaUrl: skill.iconUrl,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: isCurrent ? [80, 80] : [40, 40],
    className: `${isCurrent ? 'animate-marker' : ''}`,
    shadowUrl: '/images/skill-icons/icon-shadow.svg',
    shadowSize: [40, 40],
    shadowAnchor: [0, 50],
  });
  return elem;
};

const MyPopupMarker = (props) => {
  const {
    id,
    company,
    jobTitle,
    salaryMin,
    salaryMax,
    currency, 
    position,
    mainSkill,
    isCurrent,
  } = props.marker;
  return (
  <Marker
    onMouseOver={(e) => {
      e.target.openPopup();
    }}
    onMouseOut={(e) => {
      e.target.closePopup();
    }}
    onClick={()=>props.history.push('/offer/'+id)}
    position={position.split(',')}
    icon={icon(mainSkill, isCurrent)}
  >
    <Popup className="pin-popup">
      <p className="job-title uppercase tracking-wide text-xs text-d-blue font-medium">{jobTitle}</p>
      <p className="job-salary text-d-green uppercase tracking-wide text-sm font-bold">
        {salaryMin && salaryMax && `${convertSalary(salaryMin)}-${convertSalary(salaryMax)} ${currency}`}
        {(!salaryMin || !salaryMax) && "Negotiable"}
      </p>
      <p className="text-xs leading-tight font-medium text-gray-90">
        {company}
      </p>
    </Popup>
  </Marker>
)};

const MyMarkersList = ({ markers, history }) => {
  const items = markers.map((marker) => (
    <MyPopupMarker key={marker.id} marker={marker} history={history} />
  ));
  return <Fragment>{items}</Fragment>;
};

// const position = [52.52008, 13.405954];
const mapRef = createRef();

const MapContainer = (props) => {
  const [position, setPosition] = useState([52.52437,13.41053]);
  const [zoom, setZoom] = useState(13);
  const [offers, setOffers] = useState([]);
  const allOffers = useSelector((state) => state.offers.offers);
  const locationFilter = useSelector((state) => state.filters.location);
  const cityFilter = useSelector((state) => state.filters.city);
  const skillFilter = useSelector((state) => state.filters.skill);
  const moneyFilter = useSelector((state) => state.filters.money);
  const contractFilter = useSelector((state) => state.filters.contract);
  const seniorityFilter = useSelector((state) => state.filters.seniority);
  const languageFilter = useSelector((state) => state.filters.language);
  const currentOffer = props.currentOffer;
  useEffect(()=>{
    let offers = allOffers;
    if(locationFilter && locationFilter.country !== "Remote") {
      offers = offers.filter((item) => item.country === locationFilter.country);
    }
    if(locationFilter && locationFilter.country === "Remote") {
      offers = offers.filter((item) => item.remote);
    }
    if(cityFilter) {
      setPosition(cityFilter.position);
      setZoom(13);
      offers = offers.filter((item) => item.city === cityFilter.name);
    } else if(locationFilter) {
      setPosition(locationFilter.position);
      setZoom(7);
    }
    if(skillFilter) {
      offers = offers.filter((item) => item.mainSkill === skillFilter);
    }
    if(moneyFilter) {
      offers = offers.filter((item) => item.salaryMax >= moneyFilter);
    }
    if(contractFilter) {
      offers = offers.filter((item) => item.contractType === contractFilter);
    }
    if(seniorityFilter) {
      offers = offers.filter((item) => item.level === seniorityFilter);
    }
    if(languageFilter) {
      offers = offers.filter((item) => item.language === languageFilter);
    }
    if(currentOffer) {
      const filtered = allOffers.filter((offer)=>offer.id !== currentOffer.id);
      filtered.push({isCurrent: true, ...currentOffer});
      setPosition(currentOffer.position.split(','));
      setZoom(15);
      offers = filtered;
    }

    setOffers(offers);
  },[allOffers, locationFilter, cityFilter, skillFilter, moneyFilter, contractFilter, seniorityFilter, languageFilter, currentOffer]);

  return (
    <Map center={position} ref={mapRef} zoom={zoom} style={{ height: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />
      {offers.length > 0 && <MyMarkersList markers={offers} history={props.history} />}
    </Map>
  );
};

export default withRouter(MapContainer);
