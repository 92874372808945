import React from 'react';
import Helmet from 'react-helmet';
import PostJobForm from './components/postJobForm/PostJobForm';

const PostJobStepTwo = () => {
  return (
    <>
      <Helmet>
        <title>Post job offer - Duomly jobs</title>
        <meta charSet="utf-8" />
        <meta name="description" content='Precisely selected IT jobs from developers for developers' />
        <meta property="og:type" content='website'/>
        <meta property='og:image' content={process.env.S3_URL + 'jobs/social/jobs-open-graph.jpg'}></meta>
      </Helmet>
      <div id='post-job'>
        <div className='flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-10 md:max-w-2xl '>
          <div className='flex items-start px-4 py-6 w-full'>
            <div className='w-full'>
              <div className='flex items-center justify-between'>
                <h2 className='text-lg font-semibold text-gray-900 -mt-1'>
                  How to post the job offer?
                </h2>
              </div>
              <div className='py-3 text-sm'>
                <div className='flex justify-start text-gray-700 rounded-md py-2 my-2'>
                  <div className='flex-grow font-medium'>
                  Fill the form below, and we'll inform you when the job offer will be visible
                  on the board.
                  </div>
                </div>
              </div>
              <PostJobForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostJobStepTwo;
