import React, {useState} from 'react';
import { useSelector } from 'react-redux';

import Nav from '../../header/nav/Nav';
import Skills from '../../header/skills/Skills';
import MapContainer from '../../map/mapContainer/MapContainer';
import Details from '../../details/Details';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';

const DetailsView = (props) => {
  const offerId = props.match.params.id;
  const offers = useSelector((state) => state.offers.offers);
  const currentOffer = offers.find(offer => offer.id === Number(offerId));
  
  return (
    <>
    <Helmet>
      <title>{currentOffer && currentOffer.jobTitle ? currentOffer.jobTitle + ' - Duomly jobs' : 'Duomly Jobs'}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={currentOffer && currentOffer.description ? currentOffer.description : 'Precisely selected IT jobs from developers for developers'} />
      <meta property="og:type" content='website'/>
      <meta property='og:image' content={process.env.S3_URL + 'jobs/social/jobs-open-graph.jpg'}></meta>
    </Helmet>
    {offers.length > 0 && !currentOffer && <Redirect to="/" />}
    <div className="top-filters">
      <Skills />
    </div>
    <div className="grid grid-cols-2">
      <div className={`p-6 pt-0 details`}>
        {currentOffer && (
          <Details offer={currentOffer} props={props} />
        )}
      </div>
      <div className="map-col">
        <MapContainer currentOffer={currentOffer} />
      </div>
    </div>
    </>
  );
}

export default DetailsView;
