import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setModal,
  setContract,
  setSeniority,
  setLanguage,
  setMoney,
} from '../../../redux/actions/filtersActions';
import { convertSalary } from '../../../utils/helpers';
import Filters from '../../header/filters/Filters';

const LocationModal = () => {
  const [opacity, setOpacity] = useState('opacity-0');
  const filters = useSelector((state) => state.filters);
  const modal = useSelector((state) => state.filters.modal);
  const dispatch = useDispatch();
  useEffect(() => {
    if (modal) {
      setOpacity('opacity-1');
    }
  }, [modal]);
  return (
    <div
      className={`location-modal modal opacity-1 fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div className='modal-overlay absolute w-full h-full bg-gray-900 opacity-50'></div>

      <div className='modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto'>

        <div className='modal-content py-4 text-left px-6'>
        {/* <div class="max-w-sm rounded-lg overflow-hidden shadow-lg bg-white m-4"> */}
      <div class="flex flex-col min-h-full">
        <div class="pt-6 flex justify-center">
          <img src="/images/worried.svg" alt="" class="w-1/5" />
        </div>
  <div class="px-6 py-6 flex-grow text-center">
    <div class="text-gray-700 font-medium text-xl mb-2">We do not operate in your country yet</div>
    <p class="text-gray-600 text-sm pb-2">
    To ensure the best quality of experience, we do not operate in countries where we do not gather enough job offers.
    </p>
    <p class="text-gray-600 text-sm pb-5">
      But you can still learn coding with us!
    </p>
    <a href="https://www.duomly.com" id="no-operate-redirect" className="bg-d-pink hover:d-pink-hover text-white font-bold py-2 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mr-2">
      Learn now
    </a>
  </div>
  </div>
{/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
