export const SET_LOCATION = 'SET_LOCATION';
export const SET_CITY = 'SET_CITY';
export const SET_SKILL = 'SET_SKILL';
export const SET_MODAL = 'SET_MODAL';
export const SET_MONEY = 'SET_MONEY';
export const SET_CONTRACT = 'SET_CONTRACT';
export const SET_SENIORITY = 'SET_SENIORITY';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';