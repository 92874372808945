import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setModal,
  setContract,
  setSeniority,
  setLanguage,
  setMoney,
} from '../../../redux/actions/filtersActions';
import { convertSalary } from '../../../utils/helpers';
import Filters from '../../header/filters/Filters';

const Modal = () => {
  const [opacity, setOpacity] = useState('opacity-0');
  const filters = useSelector((state) => state.filters);
  const modal = useSelector((state) => state.filters.modal);
  const [money, setMoneyState] = useState(null);
  const [contract, setContractState] = useState(null);
  const [seniority, setSeniorityState] = useState(null);
  const [language, setLanguageState] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (modal) {
      setOpacity('opacity-1');
    }
  }, [modal]);
  const setFilters = () => {
    dispatch(setMoney(money));
    dispatch(setContract(contract));
    dispatch(setSeniority(seniority));
    dispatch(setLanguage(language));
    dispatch(setModal(false));
  }
  useEffect(()=>{
    setMoneyState(filters.money);
    setContractState(filters.contract);
    setSeniorityState(filters.seniority);
    setLanguageState(filters.language);
  },[]);
  return (
    <div
      className={`filters-modal modal ${opacity} fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div className='modal-overlay absolute w-full h-full bg-gray-900 opacity-50'></div>

      <div className='modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto'>

        <div className='modal-content py-4 text-left px-6'>
          <div className='flex justify-between items-center pb-3'>
            <p className='text-2xl font-bold'>All filters</p>
            <div
              onClick={() => dispatch(setModal(false))}
              className='modal-close cursor-pointer z-50'
            >
              <svg
                className='fill-current text-black'
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 18 18'
              >
                <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
              </svg>
            </div>
          </div>
          <div className='mb-4'>
            <p>Min salary:</p>
            <input
              className='rounded-lg overflow-hidden appearance-none bg-gray-400 h-3 w-128'
              type='range'
              min='20000'
              max='100000'
              step='1000'
              onChange={(e) => setMoneyState(e.target.value)}
              value={money}
            />
            <span className='text-d-green text-lg float-right font-semibold'>
              {money ? convertSalary(money) : convertSalary(60000)}
            </span>
          </div>
          <div className='mb-4'>
            <p>Contract type:</p>
            <button
              onClick={() => setContractState(null)}
              className={`${
                !contract
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              All
            </button>
            <button
              onClick={() => setContractState('Full-time')}
              className={`${
                contract === 'Full-time'
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              Full-time
            </button>
            <button
              onClick={() => setContractState('Freelance')}
              className={`${
                contract === 'Freelance'
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              Freelance
            </button>
          </div>

          <div className='mb-4'>
            <p>Seniority:</p>
            <button
              onClick={() => setSeniorityState(null)}
              className={`${
                !seniority
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              All
            </button>
            <button
              onClick={() => setSeniorityState('Senior')}
              className={`${
                seniority === 'Senior'
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              Senior
            </button>
            <button
              onClick={() => setSeniorityState('Mid')}
              className={`${
                seniority === 'Mid'
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              Mid
            </button>
            <button
              onClick={() => setSeniorityState('Junior')}
              className={`${
                seniority === 'Junior'
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              Junior
            </button>
          </div>

          <div className='mb-4'>
            <p>Language:</p>
            <button
              onClick={() => setLanguageState(null)}
              className={`${
                !language
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              All
            </button>
            <button
              onClick={() => setLanguageState('English')}
              className={`${
                language === 'English'
                  ? 'bg-d-blue text-white'
                  : 'bg-white text-d-blue'
              } hover:bg-d-blue font-normal hover:text-white p-1 px-2 border hover:border-transparent rounded mr-2 mb-2`}
            >
              English
            </button>
          </div>
          <Filters mobile={true}/>
          <div className='flex justify-end pt-2'>
            <button
              onClick={() => setFilters()}
              className='bg-d-pink hover:d-pink-hover text-white font-bold py-1 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mr-1'
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
