import React from 'react';

const Skill = (props) => { 

  return (
    <div onClick={()=>props.onClick()} className={`text-center py-2 mt-1 skill-item ${props.vertical ? 'vertical' : ''}`}>
      <img className="w-10 h-10 rounded-full mx-auto skill-icon" src={props.icon} alt={props.title + " icon"} />
      <p className="text-sm">{props.title}</p>
    </div>
  )
}

export default Skill;