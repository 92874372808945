import * as types from '../types/filtersTypes';

export function setLocation(location) {
  return {
    type: types.SET_LOCATION,
    payload: location,
  };
}

export function setCity(city) {
  return {
    type: types.SET_CITY,
    payload: city,
  };
}

export function setSkill(skill) {
  return {
    type: types.SET_SKILL,
    payload: skill,
  };
}

export function setModal(state) {
  return {
    type: types.SET_MODAL,
    payload: state,
  };
}

export function setMoney(min) {
  return {
    type: types.SET_MONEY,
    payload: min,
  };
}

export function setContract(contractType) {
  return {
    type: types.SET_CONTRACT,
    payload: contractType,
  };
}

export function setSeniority(level) {
  return {
    type: types.SET_SENIORITY,
    payload: level,
  };
}

export function setLanguage(language) {
  return {
    type: types.SET_LANGUAGE,
    payload: language,
  };
}

export function clearFilters() {
  return {
    type: types.CLEAR_FILTERS,
  };
}

export function getCountries(data) {
  return {
    type: types.GET_COUNTRIES,
    payload: data,
  };
}

export function setLocationFilter() {
  return {
    type: types.SET_LOCATION_FILTER,
  };
}