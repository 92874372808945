import React, { useState, useRef, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

const Nav = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef();
  const buttonRef = useRef();
  const handleClickOutside = (event) => {
    if (
      openMenu &&
      menuRef &&
      !menuRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenMenu(false);
    }
  };
  useEffect(() => {
    if (openMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [openMenu]);

  return (
    <nav className='flex items-center justify-between flex-wrap px-6 py-3 shadow-md'>
      <div className='flex items-center flex-shrink-0 text-d-blue mr-6 logo-container h-10'>
        <Link onClick={() => setOpenMenu(false)} to='/'>
          <img className='logo' src='/images/logo.svg' alt='' />
        </Link>
      </div>
      <div className='block lg:hidden'>
        <button
          ref={buttonRef}
          id='toggle-menu'
          className='flex items-center px-3 py-2 border rounded border-d-blue hover:text-d-blue hover:border-d-blue'
          onClick={() => setOpenMenu(!openMenu)}
        >
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>
      </div>
      <div
        ref={menuRef}
        className={`${
          openMenu ? 'visible-menu' : 'hidden-menu'
        } menu-positions w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
      >
        <div className='text-sm lg:flex-grow'>
          <Link
            onClick={() => setOpenMenu(false)}
            className='block mt-4 lg:inline-block lg:mt-0 text-d-blue hover:text-d-green mr-4'
            to='/'
          >
            Jobs
          </Link>
          <a
            href='https://www.duomly.com'
            target='_blank'
            rel='noopener noreferrer'
            className='block mt-4 lg:inline-block lg:mt-0 text-d-blue hover:text-d-green mr-4'
          >
            Programming courses
          </a>
          <a
            href='https://www.blog.duomly.com'
            target='_blank'
            rel='noopener noreferrer'
            className='block mt-4 lg:inline-block lg:mt-0 text-d-blue hover:text-d-green'
          >
            Blog
          </a>
        </div>
        {!props.location.pathname.includes('/post-job') && (
          <div>
            <Link
              onClick={() => setOpenMenu(false)}
              to='/post-job'
              id='post-job-nav'
              className='bg-d-pink hover:d-pink-hover text-white font-bold py-2 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mr-2'
            >
              Post Job
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default withRouter(Nav);
