import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import { useSelector, useDispatch } from 'react-redux';
import { setCity } from '../../../../redux/actions/filtersActions';

const Cities = (props) => {
  const dispatch = useDispatch();
  const selectedCity = useSelector((state) => state.filters.city);

  return (
    <div className="cities">
      <button onClick={() => dispatch(setCity(null))} className={`
        ${selectedCity === null ?
          'bg-d-blue text-white'
          : 'bg-white text-d-blue'}
         font-medium p-1 border hover:border-transparent rounded mr-2 mb-2`} key={shortid.generate()}>
          All cities
      </button>
      {props.cities && props.cities.map(city => (
        <button onClick={() => dispatch(setCity(city))} className={`
        ${selectedCity && selectedCity.name === city.name ?
          'bg-d-blue text-white'
          : 'bg-white text-d-blue'}
         font-medium p-1 border hover:border-transparent rounded mr-2 mb-2`} key={shortid.generate()}>
          {city.name}
        </button>
      ))}
    </div>
  )
}

export default Cities;