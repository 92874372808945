import React from 'react';
import { Link } from 'react-router-dom';

const DetailsHeader = (props) => {
  const { offer } = props;

  return (
    <div className="details-header max-w-1/3 bg-blue-100">
      <Link to="/"><svg className="back-icon m-4 inline-block fill-current h-10 w-10 text-d-blue p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z"/></svg></Link>
      <button onClick={() => props.apply()} className="bg-d-pink hover:bg-d-pink-hover text-white font-bold py-2 px-4 border-b-4 border-d-pink-hover hover:border-d-pink rounded m-4 float-right">
        APPLY
      </button>
      <div className="header-info p-4 flex items-center h-56">
        <div>
          <div className="h-24 w-24 lg:w-24 flex-none bg-center bg-contain bg-no-repeat text-center overflow-hidden" style={{ "backgroundImage": `url('${process.env.S3_URL + offer.companyLogo}')` }} ></div>
        </div>
        <div className="mt-4 md:mt-0 ml-4 flex-grow">
          <div className="mb-2 flex justify-between uppercase tracking-wide text-md text-d-blue font-bold">
            <p>{offer.jobTitle}</p>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="block mt-1 text-sm leading-tight font-medium text-gray-90">
                <svg className="inline-block fill-current text-gray-600 h-4 w-4 pb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z"/></svg>
                {props.offer.company}
                <span className="mt-2 text-gray-600 text-xs">
                 <svg className="inline-block fill-current text-gray-600 h-4 w-4 pb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg>
                  {props.offer.city}, {props.offer.country},
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>     
    </div>
  );
}

export default DetailsHeader;
