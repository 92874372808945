import React from 'react';
import Helmet from 'react-helmet';

const Terms = (props) => {
  return (
    <>
      <Helmet>
        <title>Term&Conditions - Duomly jobs</title>
        <meta charSet="utf-8" />
        <meta name="description" content='Precisely selected IT jobs from developers for developers' />
        <meta property="og:type" content='website'/>
        <meta property='og:image' content={process.env.S3_URL + 'jobs/social/jobs-open-graph.jpg'}></meta>
      </Helmet>
      <div id='post-job'>
        <div className='flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-10 max-w-md md:max-w-2xl '>
          <div className='flex items-start px-4 py-6'>
            <div className=''>
              <div className='flex items-center justify-between'>
                <h2 className='text-lg font-semibold text-gray-900 -mt-1'>
                  Terms & conditions
                </h2>
              </div>
              <div className='py-3 text-sm'>
                <div className='flex flex-col text-gray-700 rounded-md px-2 py-2 my-2'>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>1. Introduction</p>
                    <p>
                      By accessing and using this service, you accept and agree
                      to be bound by the terms and provisions of this agreement.
                      When using these particular services, you shall be subject
                      to any posted guidelines or rules applicable to such
                      services. Any participation in this service will
                      constitute acceptance of this agreement. If you do not
                      agree to abide by the above, please do not use this
                      service.
                    </p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>
                      2. Intellectual Property Rights
                    </p>
                    <p>
                      The Duomly Jobs portal and its original content, features,
                      and functionality are owned by How to Code s.r.o. and are
                      protected by international copyright and intellectual
                      property or proprietary rights laws.
                    </p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>
                      3. Use of Duomly Jobs portal
                    </p>
                    <p className='pb-2'>
                      This Duomly Jobs portal and its components are offered to
                      support hiring process and application for job activities
                      only; this software shall not be responsible or liable for
                      the accuracy, usefulness or availability of any
                      information transmitted or made available via the portal,
                      and shall not be responsible or liable for any error or
                      omissions in that information. 
                    </p>
                    <p className='pb-2'>
                      The Duomly Jobs portal doesn’t take responsibility for the
                      content of published job offers. They are published by the
                      users, and they are fully responsible for the job offers
                      information.
                    </p>
                    <p className='pb-2'>
                      The Duomly Jobs portal reverses the rights to remove or
                      not accept the job offers that are not correct, or contain
                      any information that may mislead the users.
                    </p>
                    <p className='pb-2'>
                      The Duomly Jobs portal doesn’t take responsibility for the
                      application's content, data, and files send to the job
                      offer publishers. 
                    </p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>4. Restrictions</p>
                    <p>
                      You are restricted explicitly from all of the following:
                      <ul className="pt-2">
                        <li className="pb-2">
                          - using this Duomly in any way that is or may be
                          damaging to this Duomly Jobs portal</li>
                          <li className="pb-2">
                          - using this
                          Duomly in any way that impacts user access to this
                          Duomly Jobs portal
                        </li>
                        <li>
                          - using this Duomly contrary to applicable laws and
                          regulations, or in any way may cause harm to the
                          Duomly Jobs portal, or to any person or business
                          entity.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>5. Termination</p>
                    <p>We may terminate your job offer published at the Duomly Jobs portal, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account and your offer. All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>6. Payment policy</p>
                    <p>Using the Duomly Jobs portal is free for the job offers and applying to them. For enabling the possibility to add a job offer, you need to pay according to the payment information in Post a Job page. Payments are processed by external payment service provider Paddle.com and How To Code S.R.O is not responsible for problems arising from the use of payment provider by the user. The amount of charge is equivalent to information placed in the pricing in the Post a Job page. We accept payment by card. In case of questions contact us at jobs@duomly.com</p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>7. Warranties</p>
                    <p>This Duomly Jobs portal is provided “as is,” with all faults, and express no representations or warranties of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
</p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>8. Changes</p>
                    <p>The company reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these terms. If there are any changes, we will announce that these changes have been made by email. If there are any changes in how we use our site, notification by email will be made to those affected by the change. You are therefore advised to re-read this statement on a regular basis.
</p>
                  </div>
                  <div className='flex-grow font-medium px-2 pb-8'>
                    <p className='font-bold pb-2'>9. Contacts</p>
                    <p>Should you have any further questions, concerns, or queries about these Terms and Conditions, or if you encounter difficulties while navigating and using the site, please contact jobs@duomly.com.
</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
