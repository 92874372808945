import * as types from '../types/filtersTypes';

const initialState = {
  location: null,
  locationFilter: false,
  city: null,
  skill: null,
  countries: [],
  modal: false,
  money: null,
  contract: null,
  // Possilbe: Full-time, Freelance
  seniority: null,
  // Possilbe: Senior, Mid, Junior
  language: null,
};

export function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case types.SET_CITY:
      return {
        ...state,
        city: action.payload,
      };
    case types.SET_SKILL:
      return {
        ...state,
        skill: action.payload,
      };
    case types.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case types.SET_MONEY:
      return {
        ...state,
        money: action.payload,
      };
    case types.SET_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case types.SET_SENIORITY:
      return {
        ...state,
        seniority: action.payload,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.CLEAR_FILTERS:
      return {
        ...state,
        location: null,
        city: null,
        skill: null,
        modal: false,
        money: null,
        contract: null,
        seniority: null,
        language: null,
      };
    case types.GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case types.SET_LOCATION_FILTER:
      return {
        ...state,
        locationFilter: true
      };
    default:
      return state;
  }
}
