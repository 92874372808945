import React, { useState, useRef, useEffect } from 'react';

import DetailsHeader from './detailsHeader/DetailsHeader';
import DetailsBoxes from './detailsBoxes/DetailsBoxes';
import Technologies from './technologies/Technologies';
import Description from './description/Description';
import JobDetails from './jobDetails/JobDetails';
import ApplicationForm from './applicationForm/ApplicationForm';

const Details = (props) => {
  const { offer } = props;
  const [apply, setApply] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (offer.quickApply) {
      setApply(true);
    }
  }, [offer])

  const handleApply = () => {
    if (offer.quickApply) {
      window.dataLayer.push({event:"scroll-apply"});
      formRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    } else {
      window.dataLayer.push({event:"apply-redirect"});
      window.location.href = offer.applyUrl;
    }
  }

  return (
    <>
      <div className="details-card max-w-auto rounded overflow-hidden shadow-lg bg-white">
        <DetailsHeader offer={offer} apply={() => handleApply()} history={props.props.history} />   
        <DetailsBoxes offer={offer} />
        <Technologies offer={offer} />
        <JobDetails offer={offer} apply={() => handleApply()} />
        <Description offer={offer} />
        {apply && (
           <ApplicationForm offer={offer} forwardedRef={formRef} />
        )}
        {!apply && (
          <button onClick={() => handleApply()} className="bg-d-pink hover:bg-d-pink-hover text-white font-bold py-2 px-4 border-b-4 border-d-pink-hover hover:border-d-pink rounded m-4 float-right">
            APPLY
          </button>
        )}
      </div>
    </>
  );
}

export default Details;
