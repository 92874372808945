import React from 'react';
import shortid from 'shortid';

const Technologies = (props) => {
  const { offer } = props;
  const allSkills = offer.allSkills && offer.allSkils !== undefined ? offer.allSkills.split(',') : null;
  const mustHave = offer.mustHave.split(',');
  const skills = allSkills ? mustHave.concat(allSkills) : mustHave;
  return (
    <div className="p-4">
      <div className="mb-4 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold">
        <p>Technologies</p>
      </div>
      <div className="flex justify-start flex-wrap">
        {skills.map((skill) => (
          <span key={shortid.generate()} className="inline-block bg-gray-200 rounded-full px-3 py-2 text-sm font-semibold text-gray-700 mr-2 mt-2">{skill}</span>
        ))}
      </div>
    </div>
  );
}

export default Technologies;
