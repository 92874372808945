import React, { useState, useEffect } from 'react';
import Region from './region/Region';
import Location from './location/Location';
import Cities from './cities/Cities';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, clearFilters } from '../../../redux/actions/filtersActions';



const Filters = (props) => {
  // const [locations, setLocations] = useState([]);
  // const [regionLocations, setRegionLocations] = useState([]);
  // const [region, setRegion] = useState('DACH');
  // const [selectedCountry, setSelectedCountry] = useState('Germany');
  const dispatch = useDispatch();
  const locationFilter = useSelector((state) => state.filters.location);
  const allOffers = useSelector((state) => state.offers.offers);
  const [cities, setCities] = useState([]);

  function isOffer(city) {
    const offer = allOffers.find((item) => (item.city === city.name));
    if(offer) {
      return true;
    }
}

  useEffect(()=>{
    if(locationFilter && locationFilter.cities) {
      const withOffers = locationFilter.cities.filter((city) => isOffer(city));
      setCities(withOffers);
    }
    
  }, [locationFilter]);

  return (
    <div className={`filter ${props.mobile ? 'mobile' : ''}`}>
      <div className={`grid grid-flow-col md:grid-flow-col-dense xl:grid-flow-col py-6 ${locationFilter && locationFilter.country !== "Remote" ? "pb-0" : ""}`}>
        <Location />
        <div className="filter-buttons">
          {!props.mobile && (
            <button onClick={()=>dispatch(setModal(true))} className="filter-btn bg-d-pink hover:d-pink-hover text-white font-bold py-1 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mr-2">
              All filters
            </button>
          )}
          <button onClick={()=>dispatch(clearFilters())} className="filter-btn bg-d-blue hover:d-blue-hover text-white font-bold py-1 px-2 border-b-4 border-d-blue-hover hover:border-d-blue rounded">
            Clear
          </button>
        </div>
      </div>
        {locationFilter && locationFilter.country !== "Remote" && (
          <div className="grid grid-flow-col sm:grid-flow-row md:grid-flow-col-dense lg:grid-flow-row-dense xl:grid-flow-col">
            <Cities cities={locationFilter && cities.length > 0 ? cities : null } />
          </div>
        )}
        

    </div>
  )
}

export default Filters;