import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import accept from 'attr-accept';
import { useDropzone } from 'react-dropzone';
import {
  possibleSeniority,
  possibleMainSkills,
  possibleLanguages,
  possibleCurrencies,
} from '../../../../../utils/possibleOptions';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

// const mocked = {
//   aboutUs: 'About us some info',
//   // aboutUs: '',
//   address: 'Mohrenstrasse 37',
//   allSkills: 'Agile',
//   // allSkills: '',
//   city: 'Berlin',
//   company: 'Duomly',
//   contractType: 'Freelance',
//   country: 'Germany',
//   currency: 'EUR',
//   description: 'Some description',
//   email: 'info@duomly.com',
//   gdpr: 'You accept GDPR',
//   // gdpr: '',
//   jobTitle: 'Senior Js dev',
//   language: 'English',
//   level: 'Senior',
//   mainSkill: 'JS',
//   mustHave: 'JS, Node.js, HTML',
//   postal: '1300',
//   remote: false,
//   // requirements: '',
//   // responsibilities: '',
//   requirements: 'Some requirements',
//   responsibilities: 'Some responsibilities',
//   salaryMax: '120000',
//   salaryMin: '30000',
// };

const emptyForm = {
  aboutUs: '',
  address: '',
  allSkills: '',
  city: '',
  company: '',
  contractType: '',
  country: '',
  currency: '',
  description: '',
  email: '',
  gdpr: '',
  jobTitle: '',
  language: '',
  level: '',
  mainSkill: '',
  mustHave: '',
  postal: '',
  remote: '',
  requirements: '',
  responsibilities: '',
  salaryMax: '',
  salaryMin: '',
};

const PostJobForm = (props) => {
  const countries = useSelector((state) => state.filters.countries);
  const [errors, setErrors] = useState(false);
  const [form, setForm] = useState(emptyForm);
  const [logo, setLogo] = useState(null);
  const [logoName, setLogoName] = useState(null);
  const [applied, setApplied] = useState(false);
  const handleInput = (key, value) => {

    if (applied === true) {
      setApplied(false);
    }
    if (key === 'remote') {
      value = value === 'true' ? true : value === 'false' ? false : null;
    }
    let keys = {
      [key]: value
    }
    // if(key === 'country') {
    //   const country = countries.find((item) => item.country === value);
    //   if(country && form.level) {
    //     keys = {
    //       ...keys,
    //       salaryMin: country.salaries[form.level].min,
    //       salaryMax: country.salaries[form.level].max,
    //       currency: country.currency
    //     }
    //   } else if(country) {
    //     keys = {
    //       ...keys,
    //       currency: country.currency
    //     }
    //   }
    // }
    if(key === 'level' && value && form.country) {
      const country = countries.find((item) => item.country === form.country);
      if(country) {
        keys = {
          ...keys,
          salaryMin: country.salaries[value].min,
          salaryMax: country.salaries[value].max,
        }
      }
    }
    if(key === 'address') {
      if(form.company && form.email && form.country && form.city) {
        keys = {
          ...keys,
          gdpr: `By submitting the form, you allow ${form.company} with H.Q. at ${value}, ${form.city} ${form.country}., to store all the submitted data in the database for the recruitment process purposes. You can always ask to delete your data from the database by sending email to ${form.email}`
        }
      }
    }
    setForm({
      ...form,
      ...keys
    });
  };

  const validation = () => {
    setErrors(false);
    if (
      form.company &&
      form.email &&
      form.country &&
      form.city &&
      form.postal &&
      form.address &&
      logo &&
      form.jobTitle &&
      form.level &&
      form.mainSkill &&
      (form.remote === true || form.remote === false ) &&
      form.contractType &&
      form.language &&
      form.salaryMin &&
      form.salaryMax &&
      form.currency &&
      form.mustHave &&
      form.description
    ) {
      return true;
    } else {
      setErrors(true);
      setApplied(false);
      return false;
    }
  };

  const submitForm = () => {
    setApplied('sending');
    const valid = validation();
    if (valid) {
      sendApplication();
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      var goodType = accept({ name: file.name, type: file.type }, 'image/*');
      if (goodType) {
        setLogoName({ preview: URL.createObjectURL(file) });
        setLogo(file);
      } else {
        setLogoName('You can upload only images');
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const sendApplication = () => {
    let formData = new FormData();
    formData.append('address', form.address);
    formData.append('companyLogo', logo);
    formData.append('city', form.city);
    formData.append('company', form.company);
    formData.append('contractType', form.contractType);
    formData.append('country', form.country);
    formData.append('currency', form.currency);
    formData.append('description', form.description);
    formData.append('email', form.email);
    formData.append('jobTitle', form.jobTitle);
    formData.append('language', form.language);
    formData.append('level', form.level);
    formData.append('mainSkill', form.mainSkill);
    formData.append('mustHave', form.mustHave);
    formData.append('postal', form.postal);
    formData.append('remote', form.remote);
    formData.append('salaryMax', form.salaryMax);
    formData.append('salaryMin', form.salaryMin);
    if(form.allSkills) {
      formData.append('allSkills', form.allSkills);
    }
    if(form.requirements) {
      formData.append('requirements', form.requirements);
    }
    if(form.responsibilities) {
      formData.append('responsibilities', form.responsibilities);
    }
    if(form.aboutUs) {
      formData.append('aboutUs', form.aboutUs);
    }
    if(form.gdpr) {
      formData.append('gdpr', form.gdpr);
    }

    fetch(process.env.BACKEND_URL + '/jobs/post-job', {
      method: 'post',
      body: formData,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.id) {
          setApplied(true);
          setForm(emptyForm);
          window.dataLayer.push({ event: 'adding-offer-success' });
        } else {
          window.dataLayer.push({ event: 'adding-offer-failed' });
          setApplied(false);
        }
      }).catch(()=>{setApplied(false)});
  };

  return (
    <form
      className='w-full'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className='section'>
        <div className='flex justify-start text-gray-700 rounded-md py-2 pt-9 my-2'>
          <div className='flex-grow font-medium'>About company</div>
        </div>
        <div className=' group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Company{' '}
              {errors && !form.company && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              type='text'
              placeholder='Company name'
              value={form.company}
              onChange={(e) => handleInput('company', e.target.value)}
            />
          </div>
          <div className='w-full md:w-1/2 px-3'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Email{' '}
              {errors && !form.email && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              type='email'
              placeholder='Email where resumes come'
              value={form.email}
              onChange={(e) => handleInput('email', e.target.value)}
            />
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Country{' '}
              {errors && !form.country && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                type='text'
                placeholder='Type country'
                value={form.country}
                onChange={(e) => handleInput('country', e.target.value)}
              />
              {/* <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.country}
                onChange={(e) => handleInput('country', e.target.value)}
              >
                <option key={shortid.generate()} value={''}>Select country</option>
                {countries.map(
                  (country) =>
                    country.country !== 'Remote' && (
                      <option key={shortid.generate()} value={country.country}>{country.country}</option>
                    )
                )}
              </select> */}
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              City{' '}
              {errors && !form.city && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              type='text'
              placeholder='Type city'
              value={form.city}
              onChange={(e) => handleInput('city', e.target.value)}
            />
          </div>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Zip{' '}
              {errors && !form.postal && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-zip'
              type='text'
              placeholder='90210'
              value={form.postal}
              onChange={(e) => handleInput('postal', e.target.value)}
            />
          </div>
        </div>
        <div className=' group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Address{' '}
              {errors && !form.address && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              type='text'
              placeholder='Type company address here'
              value={form.address}
              onChange={(e) => handleInput('address', e.target.value)}
            />
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              About us (optional)
            </label>
            <textarea
              name=''
              id=''
              cols='30'
              rows='5'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              placeholder='Add few sentences about your company'
              value={form.aboutUs}
              onChange={(e) => handleInput('aboutUs', e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Logo (square shape min 300px x 300px){' '}
              {errors && !logo && (
                <span className='text-d-pink'>Company logo is required</span>
              )}
            </label>
            <div className='dropzone' {...getRootProps()}>
              <input {...getInputProps()} />
              <p>
                {logoName ? (
                  logoName.preview ? (
                    <img src={logoName.preview} />
                  ) : (
                    logoName
                  )
                ) : (
                  'Drop your logo here, or click to select file'
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='flex justify-start text-gray-700 rounded-md py-2 pt-9 my-2'>
          <div className='flex-grow font-medium'>About the role</div>
        </div>
        <div className=' group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Position title{' '}
              {errors && !form.jobTitle && (
                <span className='text-d-pink'>Please fill out this field.</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              type='text'
              placeholder='Senior Javascript Developer'
              value={form.jobTitle}
              onChange={(e) => handleInput('jobTitle', e.target.value)}
            />
          </div>
        </div>
        {/* Level, MainSkill, Remote */}
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Seniority{' '}
              {errors && !form.level && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.level}
                onChange={(e) => handleInput('level', e.target.value)}
              >
                <option key={shortid.generate()} value={''}>Select seniority</option>
                {possibleSeniority.map((seniority) => (
                  <option key={shortid.generate()} value={seniority}>{seniority}</option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Main skill{' '}
              {errors && !form.mainSkill && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.mainSkill}
                onChange={(e) => handleInput('mainSkill', e.target.value)}
              >
                <option key={shortid.generate()} value={''}>Select main skill</option>
                {possibleMainSkills.map((skill) => (
                  <option key={shortid.generate()} value={skill.skill}>{skill.skill}</option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Remote{' '}
              {errors && form.remote !== true && form.remote !== false && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.remote}
                onChange={(e) => handleInput('remote', e.target.value)}
              >
                <option key='noremote' value={''}>Select remote</option>
                <option key='allowed' value={true}>Allowed</option>
                <option key='notallowed' value={false}>Not allowed</option>
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* Contract type, Language */}
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Contract type{' '}
              {errors && !form.contractType && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.contractType}
                onChange={(e) => handleInput('contractType', e.target.value)}
              >
                <option key='nocontract' value={''}>Select contract type</option>
                <option key='fulltime' value={'Full-time'}>Full-time</option>
                <option key='freelance' value={'Freelance'}>Freelance</option>
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Language{' '}
              {errors && !form.language && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.language}
                onChange={(e) => handleInput('language', e.target.value)}
              >
                <option key='nolanguage' value={''}>Select language</option>
                {possibleLanguages.map((language) => (
                  <option key={shortid.generate()} value={language}>{language}</option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* minSalary, maxSalary, currency */}
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Min salary{' '}
              {errors && !form.salaryMin && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-zip'
              type='number'
              placeholder='Yearly salary'
              value={form.salaryMin}
              onChange={(e) => handleInput('salaryMin', e.target.value)}
            />
          </div>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Max salary{' '}
              {errors && !form.salaryMax && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-zip'
              type='text'
              placeholder='Yearly salary'
              value={form.salaryMax}
              onChange={(e) => handleInput('salaryMax', e.target.value)}
            />
          </div>
          <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Currency{' '}
              {errors && !form.currency && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-state'
                value={form.currency}
                onChange={(e) => handleInput('currency', e.target.value)}
              >
                <option key='nocurrency' value={''}>Select currency</option>
                {possibleCurrencies.map((curency) => (
                  <option key={shortid.generate()} value={curency}>{curency}</option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* Must have skills and all skills */}
        <div className=' group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              3 must have skills{' '}
              {errors && !form.mustHave && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              type='text'
              placeholder='Separate skills by ,'
              value={form.mustHave}
              onChange={(e) => handleInput('mustHave', e.target.value)}
            />
          </div>
          <div className='w-full md:w-1/2 px-3'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              All skills (optional)
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              type='text'
              placeholder='Separate skills by ,'
              value={form.allSkills}
              onChange={(e) => handleInput('allSkills', e.target.value)}
            />
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Job description{' '}
              {errors && !form.description && (
                <span className='text-d-pink'>Required</span>
              )}
            </label>
            <textarea
              name=''
              id=''
              cols='30'
              rows='5'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              placeholder='Add few sentences about your company'
              value={form.description}
              onChange={(e) => handleInput('description', e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Responsibilities (optional)
            </label>
            <textarea
              name=''
              id=''
              cols='30'
              rows='5'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              placeholder='Add few sentences about role responsibilities'
              value={form.responsibilities}
              onChange={(e) => handleInput('responsibilities', e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              Role requirements (optional)
            </label>
            <textarea
              name=''
              id=''
              cols='30'
              rows='5'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              placeholder='Add few sentences about role requirements'
              value={form.requirements}
              onChange={(e) => handleInput('requirements', e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='group flex flex-wrap -mx-3 mb-10'>
          <div className='w-full px-3 mb-6 md:mb-0'>
            <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
              GDPR note (optional)
            </label>
            <textarea
              name=''
              id=''
              cols='30'
              rows='5'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
              placeholder='GDPR note that candidate accepts by applying'
              value={form.gdpr}
              onChange={(e) => handleInput('gdpr', e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      {applied !== true && (
        <>
          <p className='text-xs text-gray-500 pb-2'>
            By submitting the form, you allow How to Code S.R.O. with H.Q. at
            Rybna 716/24, Prague C.R., to store all the submitted data in the
            database to make a visible job offer. All the submitted data will be
            publicly visible to the end-user, and you can always ask to delete
            your data from the database by sending email to jobs@duomly.com.
          </p>
          <p className='text-xs text-gray-500'>
            By submitting the form you accept our{' '}
            <Link to='/terms'>Terms & Conditions.</Link>
          </p>
          {errors && (
            <p className='text-xs text-d-pink'>
              Fill all required fields, please.
            </p>
          )}
          
            <button
            onClick={() => submitForm()}
            disabled={applied==='sending'}
            id='post-job-nav'
            className='bg-d-pink hover:d-pink-hover text-white font-bold py-2 px-2 border-b-4 border-d-pink-hover hover:border-d-pink rounded mt-2 float-right'
          >
            {applied !== 'sending' && 'Submit'}
            {applied === 'sending' && <img className="timer-icon" src="/images/timer.svg"/>}
          </button>
        </>
      )}
      {applied === true && (
        <div className='max-w-sm w-full lg:max-w-full lg:flex'>
          <div className='mb-8'>
            <div className='text-gray-900 font-bold text-d-green text-2xl mb-2'>
              Thank you for your submission!
            </div>
            <p className='text-gray-700 text-base'>
              We'll send you email when your offer will be visible.
            </p>
          </div>
        </div>
      )}
    </form>
  );
};

export default PostJobForm;
