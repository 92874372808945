import React from 'react';
import { convertSalary } from '../../../utils/helpers';

const DetailsBoxes = (props) => {
  const { offer } = props;

  return (
    <div className="description-boxes flex justify-around -mt-6 mb-6">
      <div className="w-1/4 p-4 shadow-md bg-white desc-box">
        <svg className="fill-current h-6 w-6 text-d-blue mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z"/></svg>
        <div className="mb-2 mt-2 uppercase text-center tracking-wide text-md text-d-blue font-bold">
          <p>Salary</p>
        </div>
        {offer.salaryMin && offer.salaryMax && (
          <p className="mb-2 text-center uppercase tracking-wide text-sm text-d-green font-bold">
            {convertSalary(offer.salaryMin)}-{convertSalary(offer.salaryMax)} {offer.currency}
          </p>  
        )}
        {!offer.salaryMin && !offer.salaryMax && (
          <p className="mb-2 text-center uppercase tracking-wide text-sm text-d-green font-bold">
            Negotiable
          </p>  
        )}
      </div>
      <div className="w-1/4 p-4 shadow-md bg-white desc-box">
        <svg className="fill-current h-6 w-6 text-d-blue mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M3.33 8L10 12l10-6-10-6L0 6h10v2H3.33zM0 8v8l2-2.22V9.2L0 8zm10 12l-5-3-2-1.2v-6l7 4.2 7-4.2v6L10 20z"/></svg>
        <div className="mb-2 mt-2 uppercase text-center tracking-wide text-md text-d-blue font-bold">
          <p>Seniority</p>
        </div>
        <p className="mb-2 text-center uppercase tracking-wide text-sm text-d-green font-bold">
          {offer.level}
        </p>
      </div>
      <div className="w-1/4 p-4 shadow-md bg-white desc-box">
        <svg className="fill-current h-6 w-6 text-d-blue mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"/></svg>
        <div className="mb-2 mt-2 uppercase text-center tracking-wide text-md text-d-blue font-bold">
          <p>Job type</p>
        </div>
        <p className="mb-2 text-center uppercase tracking-wide text-sm text-d-green font-bold">
          {offer.contractType}
        </p>
      </div>
    </div>
  );
}

export default DetailsBoxes;
