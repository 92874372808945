import React, { useState, Component, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import accept from 'attr-accept';
import { Link } from 'react-router-dom';

const ApplicationForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cv, setCV] = useState('');
  const [cvName, setCvName] = useState('');
  const [errors, setErrors] = useState(false);
  const [applied, setApplied] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      var goodType = accept({ name: file.name, type: file.type }, [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/x-iwork-pages-sffpages',
      ]);
      if (goodType) {
        setCvName(file.name);
        setCV(file);
      } else {
        setCvName('You can upload .pdf, .doc, .docx, .pages, and .txt');
      }
    });
  }, []);

  const sendApplication = (form) => {
    let formData = new FormData();
    formData.append('jobTitle', form.jobTitle);
    formData.append('id', form.id);
    formData.append('cv', form.cv);
    formData.append('name', form.name);
    formData.append('email', form.email);
    formData.append('companyEmail', form.companyEmail);
    fetch(process.env.BACKEND_URL +'/jobs/apply', {
      method: 'post',
      body: formData,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.msg === 'Thanks for the application') {
          setApplied(true);
          window.dataLayer.push({event:"success-quick-apply"});
        } else {
          window.dataLayer.push({event:"server-failed-quick-apply"});
        }
      });
  };

  const apply = () => {
    setApplied('sending');
    setErrors(false);
    if (name && email && cv) {
      window.dataLayer.push({event:"validation-success-quick-apply"});
      sendApplication({
        name,
        email,
        cv,
        jobTitle: props.offer.jobTitle,
        id: props.offer.id,
        companyEmail: props.offer.email
      });
    } else {
      window.dataLayer.push({event:"validation-failed-quick-apply"});
      setErrors(true);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className='p-4 mt-4' ref={props.forwardedRef}>
      {applied !== true && (
        <>
          <div className='mb-2 mt-2 uppercase text-left tracking-wide text-md text-d-blue font-bold'>
            <p>Apply</p>
          </div>
          <form
            className='w-full'
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-first-name'
                >
                  Your Name{' '}
                  {errors && !name && (
                    <span className='text-d-pink'>(Name is required)</span>
                  )}
                </label>
                <input
                  className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                  id='name'
                  type='text'
                  placeholder='Your name'
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-last-name'
                >
                  Your email{' '}
                  {errors && !email && (
                    <span className='text-d-pink'>(Email is required)</span>
                  )}
                </label>
                <input
                  className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='email'
                  type='email'
                  placeholder='Your email'
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3'>
              <div className='w-full px-3'>
                <label
                  className='inputfile-label block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-password'
                >
                  Upload your Resume{' '}
                  {errors && (!cv || !cvName) && (
                    <span className='text-d-pink'>(Resume is required)</span>
                  )}
                </label>
                <div className='dropzone' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>
                    {cvName
                      ? cvName
                      : 'Drop your CV here, or click to select file'}
                  </p>
                </div>
                <p className='text-gray-600 text-xs italic'></p>
              </div>
            </div>
            {props.offer.gdpr && (
              <p className="text-xs text-gray-500">
                {props.offer.gdpr}
              </p>
            )}
            <p className="text-xs text-gray-500">By clicking apply you accept our <Link to="/terms">Terms & Conditions</Link></p>

            <div className='flex justify-end'>
              <button
                disabled={applied === 'sending'}
                onClick={() => apply()}
                className='bg-d-pink hover:bg-d-pink-hover text-white font-bold py-2 px-4 border-b-4 border-d-pink-hover hover:border-d-pink rounded float-right'
              >
                {applied !== 'sending' && 'Apply'}
                {applied === 'sending' && <img className="timer-icon" src="/images/timer.svg"/>}
              </button>
            </div>
          </form>
        </>
      )}
      {applied === true && (
        <div className="max-w-sm w-full lg:max-w-full lg:flex">
          <div className="mb-8">
            <div className="text-gray-900 font-bold text-d-green text-2xl mb-2">Thank you for your application!</div>
            <p className="text-gray-700 text-base">We've shared your resume with the author of the job offer.</p>
          </div>
      </div>
      )}
    </div>
  );
};

export default ApplicationForm;
