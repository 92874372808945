import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import ReduxThunk from 'redux-thunk'
import { offersReducer } from './reducers/offersReducer';
import { filtersReducer } from './reducers/filtersReducer';

const middlewares = [
  ReduxThunk,
];

const enhancers = [];
const devToolsExtension = window.devToolsExtension;
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(
  combineReducers({
    offers: offersReducer,
    filters: filtersReducer
  }),
  {}, // initial state
  composedEnhancers,
);

export default store;