import * as types from '../types/offersTypes';

const initialState = {
  offers: [],
};

export function offersReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_OFFERS:
      return {
        ...state,
        offers: action.payload,
      };
    default:
      return state;
  }
}
