import React from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { convertSalary } from '../../../utils/helpers';

const Item = (props) => { 
  const { offer } = props;
  const mustHave = offer.mustHave.split(',').splice(0,3);

  return (
    <Link to={`/offer/${props.offer.id}`}>
      <div className='offer-item max-w rounded overflow-hidden shadow-lg p-4 bg-white mb-2'>
        <div className='flex items-center'>
          <div className='mt-1'>
            <div
              className='company-logo h-12 w-12 lg:w-12 flex-none bg-center bg-contain bg-no-repeat text-center overflow-hidden'
              style={{ backgroundImage: `url('${process.env.S3_URL + props.offer.companyLogo}')` }}
            ></div>
          </div>
          <div className='mt-4 md:mt-0 ml-4 flex-grow'>
            <div className='mb-2 flex justify-between uppercase tracking-wide text-sm text-d-blue font-bold'>
              <p>{props.offer.jobTitle}</p>
              {props.offer.salaryMin && props.offer.salaryMax && (
                <p className="text-d-green">
                  {convertSalary(offer.salaryMin)}-{convertSalary(offer.salaryMax)} {offer.currency}
                </p>
              )}
              {!props.offer.salaryMin && !props.offer.salaryMax && (
                <p className='text-d-green'>Negotiable</p>
              )}
            </div>
            <div className='company-info flex justify-between'>
              <div>
                <p className='block mt-1 text-sm leading-tight font-medium text-gray-90'>
                  <svg
                    className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z' />
                  </svg>
                  {props.offer.company}
                  <span className='mt-2 text-gray-600 text-xs'>
                    <svg
                      className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' />
                    </svg>
                    {props.offer.city}, {props.offer.country}
                  </span>
                </p>
              </div>
              <div className="job-properties">
                {props.offer.quickApply && (
                  <span className='inline-block bg-d-pink-light rounded-full px-2 py-0 text-xs font-medium text-white mr-2'>
                    Quick Apply
                  </span>
                )}
                {props.offer.remote && (
                  <span className='inline-block bg-d-green-light rounded-full px-2 py-0 text-xs font-medium text-white mr-2'>
                    Remote
                  </span>
                )}
              </div>
              <div className="job-skills">
                {mustHave &&
                  mustHave.map((skill) => (
                    <span
                      className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                      key={shortid.generate()}
                    >
                      {skill}
                    </span>
                  ))}
              </div>
            </div>
            <div className='company-info-mobile'>
              <div>
                <p className='block mt-1 text-sm leading-tight font-medium text-gray-90'>
                  <svg
                    className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z' />
                  </svg>
                  {props.offer.company}
                  <span className='mt-2 text-gray-600 text-xs'>
                    <svg
                      className='inline-block fill-current text-gray-600 h-4 w-4 pb-1'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' />
                    </svg>
                    {props.offer.city}, {props.offer.country}
                  </span>
                </p>
              </div>
              <div className="first-row">
                <div className="job-properties">
                  {props.offer.quickApply && (
                    <span className='inline-block bg-d-pink-light rounded-full px-2 py-0 text-xs font-medium text-white mr-2'>
                      Quick Apply
                    </span>
                  )}
                  {props.offer.remote && (
                    <span className='inline-block bg-d-green-light rounded-full px-2 py-0 text-xs font-medium text-white mr-2'>
                      Remote
                    </span>
                  )}
                </div>
                <div className="job-skills">
                  {mustHave &&
                    mustHave.map((skill) => (
                      <span
                        className='inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-medium text-gray-700 ml-2'
                        key={shortid.generate()}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </Link>
  );
};

export default Item;
