import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Item from './item/Item';
import shortid from 'shortid';
import BlankItem from './blankItem/BlankItem';

// money: null,
// contract: null,
// seniority: null,
// language: null,

const List = () => {
  const allOffers = useSelector((state) => state.offers.offers);
  const locationFilter = useSelector((state) => state.filters.location);
  const cityFilter = useSelector((state) => state.filters.city);
  const skillFilter = useSelector((state) => state.filters.skill);
  const moneyFilter = useSelector((state) => state.filters.money);
  const contractFilter = useSelector((state) => state.filters.contract);
  const seniorityFilter = useSelector((state) => state.filters.seniority);
  const languageFilter = useSelector((state) => state.filters.language);
  const [offers, setOffers] = useState([]);
  useEffect(()=>{
    let offers = allOffers;
    if(locationFilter && locationFilter.country !== "Remote") {
      offers = offers.filter((item) => item.country === locationFilter.country);
    }
    if(locationFilter && locationFilter.country === "Remote") {
      offers = offers.filter((item) => item.remote);
    }
    if(cityFilter) {
      offers = offers.filter((item) => item.city === cityFilter.name);
    }
    if(skillFilter) {
      offers = offers.filter((item) => item.mainSkill === skillFilter);
    }
    if(moneyFilter) {
      offers = offers.filter((item) => item.salaryMax >= moneyFilter);
    }
    if(contractFilter) {
      offers = offers.filter((item) => item.contractType === contractFilter);
    }
    if(seniorityFilter) {
      offers = offers.filter((item) => item.level === seniorityFilter);
    }
    if(languageFilter) {
      offers = offers.filter((item) => item.language === languageFilter);
    }
    offers = offers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setOffers(offers);
},[allOffers, locationFilter, cityFilter, skillFilter, moneyFilter, contractFilter, seniorityFilter, languageFilter]);
  return (
    <>
      {offers.length > 0 && offers.map((offer) => (
        <Item offer={offer} key={shortid.generate()} />
      ))}
      {(!offers || offers.length === 0) && <BlankItem key={shortid.generate()} />}
    </>
  );
}

export default List;
