import React, { useState } from 'react';
import shortid from 'shortid';
import Skill from './skill/Skill';
import { possibleMainSkills } from '../../../utils/possibleOptions';
import { useDispatch, useSelector } from 'react-redux';
import { setSkill } from '../../../redux/actions/filtersActions';
import { withRouter } from 'react-router-dom';

const Skills = (props) => {
  const screenWidth = document.body.clientWidth;
  const dispatch = useDispatch();
  const [skills] = useState(possibleMainSkills);
  const technology = useSelector((state) => state.filters.skill);
  const [skillsPanel, toggleSkills] = useState(false);

  const selectSkill = (skill) => {
    props.history.push('/');
    dispatch(setSkill(skill));
  };

  return (
    <div id='skill-bar'>
      <div className='technologies flex flex-row justify-start p-2'>
        <Skill
          onClick={() => selectSkill(null)}
          key={shortid.generate()}
          title='All'
          icon={'/images/skill-icons/all-icon.svg'}
        />
        {skills.map((skill) => (
          <Skill
            onClick={() => selectSkill(skill.skill)}
            key={shortid.generate()}
            title={skill.skill}
            icon={skill.iconUrl}
          />
        ))}
      </div>
      <div className='technologies-mobile p-2 mx-auto'>
        <button
          onClick={() => toggleSkills(!skillsPanel)}
          className='open-skills focus:border-transparent bg-transparent text-d-blue font-semibold py-2 px-4 hover:border-transparent rounded focus:outline-none inline-flex'
        >
          Skills
          <svg
            className='w-6 h-6 pt-1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
          >
            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
          </svg>
        </button>
        <div className={`skills-container ${skillsPanel ? 'show' : 'hide'}`}>
          <div
            onClick={() => toggleSkills(!skillsPanel)}
            className='close-skills cursor-pointer z-50'
          >
            <svg
              className='fill-current text-black'
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              viewBox='0 0 18 18'
            >
              <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
            </svg>
          </div>
          <Skill
            onClick={() => {
              toggleSkills(false);
              selectSkill(null);
            }}
            vertical={true}
            key={shortid.generate()}
            title='All'
            icon={'/images/skill-icons/default-icon.svg'}
          />
          {skills.map((skill) => (
            <Skill
              onClick={() => {
                toggleSkills(false);
                selectSkill(skill.skill);
              }}
              vertical={true}
              key={shortid.generate()}
              title={skill.skill}
              icon={skill.iconUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Skills);
